import React from 'react';

import SEO from '../components/SEO';

const NotFoundPage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '16px 0px',
    }}
  >
    <SEO title='404: Not found' description='page not found' />
    <h1>🤖 404: Not Found 🤖</h1>
    <p style={{ fontSize: '1.5rem', textAlign: 'center' }}>
      You just hit a route that doesn&#39;t exist... ᓚᘏᗢ
    </p>
  </div>
);

export default NotFoundPage;
